import React, {Component} from 'react'
import {FormGroup, Form, Input, Button} from 'reactstrap'
import {withRouter} from 'react-router-dom'
import axios from 'axios'

import {toProperCase} from '../../../utilities/string'

import config from '../../../config'

import UserSetupCreate from './UserSetupCreate'

class UserSetup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            inputSearch: '',
            message: '',
            remoteUsers: null,
            selectedRemoteUser: null
        }
    }

    handleChangeInputSearch = (event) => {
        event.preventDefault()
        this.setState({inputSearch: event.target.value})
    }
    handleSearch = (event) => {
        event.preventDefault()
        if (this.state.inputSearch.length < 3) {
            this.setState({remoteUsers: null, message: 'Please search with at least 3 characters.'})
        } else {
            this.setState({message: ''})
            return axios.get(config.apiServer + '/usersRemote', {
                params: {
                    search: this.state.inputSearch
                }
            }).then(response => {
                this.setState({remoteUsers: response.data})
            }).catch(error => {
                console.log(error)
            })
        }
    }

    handleSelectUser = (item) => {
        this.setState({selectedRemoteUser: item})
    }

    handleCompleteSetupRemoteUser = () => {
        this.setState({selectedRemoteUser: null})
    }

    handleCancel = () => {
        this.props.history.push('/')
    }

    render() {
        return (<div className="mt-5">

            <h2 className="mb-4">Setup new account:</h2>

            {
                (!this.state.selectedRemoteUser) && <div>
                        <h3 className="mb-2">Search by entering your name or email address</h3>
                        <Form className="mb-4" onSubmit={this.handleSearch}>
                            <FormGroup>
                                <Input type="text" name="search" id="search" placeholder="Name / Email" value={this.state.inputSearch} onChange={this.handleChangeInputSearch}/>
                            </FormGroup>
                            <Button color="custom-secondary mr-3" onClick={this.handleCancel}>CANCEL</Button>
                            <Button color="custom-primary" onClick={this.handleSearch}>SEARCH</Button>
                        </Form>

                        {this.state.message !== '' && <div className="text-warning mt-2">{this.state.message}</div>}

                        {(this.state.remoteUsers && this.state.remoteUsers.length === 0) && <div className="text-warning mt-4">No users found</div>}

                        {
                            (this.state.remoteUsers && this.state.remoteUsers.length > 0) && <div className="mb-4">
                                    <h3 className="mb-3">Select user:</h3>
                                    {
                                        this.state.remoteUsers.map(item => {
                                            let name = toProperCase(item.firstName + ' ' + item.lastName)
                                            return <div className="mb-2" key={item.uniqueId}>
                                                <Button color="custom-secondary" value={item.uniqueId} onClick={() => this.handleSelectUser(item)}>
                                                    <div>{name}</div>
                                                </Button>
                                            </div>
                                        })
                                    }
                                </div>
                        }
                    </div>
            }

            {this.state.selectedRemoteUser && <UserSetupCreate remoteUser={this.state.selectedRemoteUser} onComplete={this.handleCompleteSetupRemoteUser}/>}

        </div>)
    }
}

export default withRouter(UserSetup)