import React, { Component } from 'react'
import {
    Form, FormGroup, Input, Label, Button
} from 'reactstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from '../../../actions/machinesActions'

class MachineForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            weightMin: 10,
            weightMax: 250,
            weightIncrements: 10
        }
    }

    componentDidMount() {
        const { data } = this.props
        const { weightMin, weightMax, weightIncrements } = this.state

        if (data) {
            this.setState({
                name: data.name || '',
                weightMin: data.weightMin || weightMin,
                weightMax: data.weightMax || weightMax,
                weightIncrements: data.weightIncrements || weightIncrements
            })
        }
    }

    handleChangeName = (event) => {
        this.setState({ name: event.target.value })
    }

    handleChangeWeightMin = (event) => {
        this.setState({ weightMin: event.target.value })
    }

    handleChangeWeightMax = (event) => {
        this.setState({ weightMax: event.target.value })
    }

    handleChangeWeightIncrements = (event) => {
        this.setState({ weightIncrements: event.target.value })
    }

    handleSubmit = (event) => {
        const {
            data, mode, onComplete, activeFacilityId, machinesActions
        } = this.props
        const {
            name, weightMin, weightMax, weightIncrements
        } = this.state

        const params = {
            name,
            weightMin,
            weightMax,
            weightStep: weightIncrements,
            facilityId: activeFacilityId
        }
        if (mode === 'edit') {
            params.id = data.id
            machinesActions.updateMachine(params)
        } else {
            machinesActions.createMachine(params)
        }
        onComplete()
    }

    handleCancel = (event) => {
        const { onComplete } = this.props
        onComplete()
    }

    render() {
        const {
            name, weightMin, weightMax, weightIncrements
        } = this.state

        return (
            <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                    <Label for="name">Machine Name</Label>
                    <Input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Machine Name"
                        value={name}
                        onChange={this.handleChangeName}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="weightMin">Weight Minimum</Label>
                    <Input
                        type="number"
                        name="weightMin"
                        id="weightMin"
                        placeholder="Weight Minimum"
                        value={weightMin}
                        onChange={this.handleChangeWeightMin}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="weightMax">Weight Maximum</Label>
                    <Input
                        type="number"
                        name="weightMax"
                        id="weightMax"
                        placeholder="Weight Maximum"
                        value={weightMax}
                        onChange={this.handleChangeWeightMax}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="weightIncrements">Weight Increments</Label>
                    <Input
                        type="number"
                        name="weightIncrements"
                        id="weightIncrements"
                        placeholder="Weight Increments"
                        value={weightIncrements}
                        onChange={this.handleChangeWeightIncrements}
                    />
                </FormGroup>

                <Button color="custom-secondary mr-3" onClick={this.handleCancel}>CANCEL</Button>
                <Button color="custom-primary" onClick={this.handleSubmit}>SUBMIT</Button>
            </Form>
        )
    }
}

function mapStateToProps(state) {
    return {
        activeFacilityId: state.facilities.activeFacilityId
    }
}

function mapDispatchToProps(dispatch) {
    return {
        machinesActions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MachineForm)
