import React, {Component} from 'react'
import {
		Row,
		Col,
		Form,
		FormGroup,
		Label,
		Input,
		Button
} from 'reactstrap'
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios'

import config from '../../../config'

class UserSetPassword extends Component {

		constructor(props) {
				super(props)

				this.state = {
						formEnabled: true,
						message: ''
				}
				console.log(props)
		}

		handleInputChange = (event) => {
				const target = event.target;
				const value = target.type === 'checkbox'
						? target.checked
						: target.value;
				const name = target.name;

				this.setState({[name]: value})
		}

		handleSubmit = (event) => {
				event.preventDefault()

				if (!this.state.formEnabled) 
						return

				let messages = []
				if (!this.state.passwordCurrent) {
						messages.push('Please provide current password')
				}
				if (!this.state.passwordNew) {
						messages.push('Please provide new password')
				}
				if (!this.state.passwordNewConfirm) {
						messages.push('Please provide confirmation password')
				}
				if (this.state.passwordNew !== this.state.passwordNewConfirm) {
						messages.push('New password does not match confirmation password')
				}

				this.setState({
						formEnabled: false,
						messages: messages
				}, () => {
						if (messages.length === 0) {

								const item = {
										password: this.state.passwordCurrent,
										passwordNew: this.state.passwordNew
								}

								axios
										.put(config.apiServer + config.apiServerPrefix + '/user/' + this.props.activeUserId, item, {
										headers: {
												Authorization: 'Bearer ' + this.props.token
										}
								})
										.then(response => {
												this.setState({formEnabled: true, messages: ['Password successfully updated.'], success: true})
										})
										.catch(error => {
												this.setState({formEnabled: true, messages: ['Error: Unable to update your password.']})
										})
						} else {
								this.setState({formEnabled: true})
						}
				})
		}

		render() {

				if (!this.props.activeUserIsAdmin) {
						return (<Redirect to="/"/>)
				}

				return (
						<div className="Page UserSetPassword">
								<Row>
										<Col sm="12" md="6" className="mb-5 mb-md-0">
												<h2 className="mb-4">Change your password:</h2>

												<Form className="mb-4" onSubmit={this.handleSubmit}>
														<FormGroup>
																<Label for="loginPassword">Current Password</Label>
																<Input
																		type="password"
																		name="passwordCurrent"
																		id="passwordCurrent"
																		onChange={this.handleInputChange}
																		placeholder=""
																		required/>
														</FormGroup>
														<FormGroup>
																<Label for="loginPassword">New Password</Label>
																<Input
																		type="password"
																		name="passwordNew"
																		id="passwordNew"
																		onChange={this.handleInputChange}
																		placeholder=""
																		required/>
														</FormGroup>
														<FormGroup>
																<Label for="loginPasswordConfirm">Confirm Password</Label>
																<Input
																		type="password"
																		name="passwordNewConfirm"
																		id="passwordNewConfirm"
																		placeholder=""
																		onChange={this.handleInputChange}
																		required/>
														</FormGroup>

														{(this.state.messages) && this.state.messages.map((message, index) => {
																return <div key={index}>{message}</div>
														})}

														<Button color="custom-primary w-100" onClick={this.handleSubmit}>Submit</Button>

												</Form>

										</Col>
								</Row>
						</div>
				)
		}
}

function mapStateToProps(state) {
		const retVal = {
				activeUserId: state.users.activeUserId,
				activeUserIsAdmin: state.users.activeUserIsAdmin,
				token: state.users.token
		}

		return retVal
}

function mapDispatchToProps(dispatch) {
		return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSetPassword)
