import React, {Component} from 'react'
import {Container, Row, Col, Button} from 'reactstrap'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import './Header.scss'

import logo from '../../images/logo-white.png'

class Header extends Component {
    handleSetup = () => {
        this.props.history.push('/userSetup')
    }

    render() {
        return (<div className="Header pt-3 pb-4">
            <Container>
                <Row noGutters={true}>
                    <Col><img className="logo" src={logo} alt="Logo"/></Col>
                    <Col className="machine text-center">
                        {this.props.activeMachine && <h1>{this.props.activeMachine.name}</h1>}
                    </Col>
                    <Col className="text-right">
                        {!this.props.activeUserId && <Button color="custom-primary" onClick={this.handleSetup}>NEW USER</Button>}
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}

function mapStateToProps(state) {
    const retVal = {
        activeUserId: state.users.activeUserId,
        activeMachine: state.machines.activeMachineData
    }

    return retVal
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
