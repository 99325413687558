import { SET_ACTIVE_MACHINE, FETCH_MACHINES, RECEIVE_MACHINES } from '../actions/actionTypes'

const initialState = {
    activeMachineId: null,
    activeMachineData: null,
    machines: []
}

export default function machines(state = initialState, action) {
    switch (action.type) {
    case SET_ACTIVE_MACHINE:
        console.log('SET_ACTIVE_MACHINE Action')
        return Object.assign({}, state, {
            activeMachineId: action.machine.id || null,
            activeMachineData: action.machine || {}
        })
    case FETCH_MACHINES:
        console.log('FETCH_MACHINES Action')
        return state
    case RECEIVE_MACHINES:
        console.log('RECEIVE_MACHINES Action')
        return Object.assign({}, state, { machines: action.machines })
    default:
        return state
    }
}
