import React, {Component} from 'react'
import {Tooltip} from 'reactstrap'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import * as usersActions from '../../actions/usersActions'

class ChartRoutineExercise extends Component {

		constructor(props) {
				super(props)

				this.state = {
						tooltipOpen: false
				}
		}

		toggle = () => {
				this.setState({
						tooltipOpen: !this.state.tooltipOpen
				})
		}

		handleClick = () => {
				this
						.props
						.usersActions
						.setUserReportMachine([this.props.exercise.Machine.id])
		}

		render() {
				const tipId = "tip_" + this.props.exercise.id

				let showInteractive = true
				if (process.env.REACT_APP_MODE === 'APP') { // don't show intereactivity if in app mode
						showInteractive = false
				}
				if (this.props.selectedMachines.length > 0 && this.props.selectedMachines[0] !== 'ALL') { // or in selected machine mode
						showInteractive = false
				}

				if (showInteractive) {
						return (
								<span className="exercise" style={this.props.styles} onClick={this.handleClick}>
										<span className="filler" id={tipId} style={this.props.stylesFiller}></span>
										<Tooltip
												placement="top"
												isOpen={this.state.tooltipOpen}
												target={tipId}
												toggle={this.toggle}>
												{this.props.exercise.Machine.name}
										</Tooltip>
								</span>
						)
				}

				return (
						<span className="exercise" style={this.props.styles}>
								<span className="filler" id={tipId} style={this.props.stylesFiller}></span>
						</span>
				)

		}
}

function mapStateToProps(state) {
		const retVal = {
				selectedMachines: state.users.reportMachines
		}

		return retVal
}

function mapDispatchToProps(dispatch) {
		return {
				usersActions: bindActionCreators(usersActions, dispatch)
		}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartRoutineExercise)