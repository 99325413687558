import { SET_ACTIVE_FACILITY, RECEIVE_FACILITIES } from '../actions/actionTypes'

const initialState = {
    activeFacilityId: null,
    activeFacilityData: null,
    facilities: []
}

export default function facilities(state = initialState, action) {
    switch (action.type) {
    case SET_ACTIVE_FACILITY:
        console.log('SET_ACTIVE_FACILITY Action', action.facility)
        return Object.assign({}, state, {
            activeFacilityId: action.facility.id || null,
            activeFacilityData: action.facility || {}
        })
    case RECEIVE_FACILITIES:
        console.log('RECEIVE_FACILITIES Action')
        return Object.assign({}, state, { facilities: action.facilities })
    default:
        return state
    }
}
