import React, {Component} from 'react'
import {Button} from 'reactstrap'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import axios from 'axios'
import {bindActionCreators} from 'redux'

import * as usersActions from '../../../actions/usersActions'

import config from '../../../config'
import colorsUtils from '../../../utilities/colors'

import ChartRoutine from '../../charts/ChartRoutine'

class UserEndRoutine extends Component {
		constructor(props) {
				super(props)

				this.state = {
						totalWeight: 0,
						maxRoutineWeight: 0,
						maxTotalWeight: 0,
						routines: [],
						machines: []
				}
		}

		componentWillMount() {
				axios
						.get(config.apiServer + config.apiServerPrefix + '/user/stats', {
						headers: {
								Authorization: 'Bearer ' + this.props.token
						}
				})
						.then(response => {
								this.setState({
										totalWeight: Number(response.data.totalWeight),
										maxRoutineWeight: Number(response.data.maxRoutineWeight)
								})
						})

				axios.get(config.apiServer + config.apiServerPrefix + '/routineCompleted', {
						headers: {
								Authorization: 'Bearer ' + this.props.token
						},
						params: {
								limit: 7
						}
				}).then(response => {
						// console.log(response.data)
						let maxTotalWeight = 0
						let machineIds = []
						let machines = []
						let routines = response
								.data
								.items
								.map(routine => {
										routine.totalWeight = 0
										routine
												.exercises
												.forEach(exercise => {
														if (!machineIds.includes(exercise.machineId)) {
																machineIds.push(exercise.machineId)
																machines.push(exercise.Machine)
														}
														routine.totalWeight += exercise.reps * exercise.weight
												})
										if (routine.totalWeight > maxTotalWeight) {
												maxTotalWeight = routine.totalWeight
										}
										return routine
								})

						// console.log(machineIds.length, machines.length, machines)
						const colors = colorsUtils.selectColorsHSL(machineIds.length)
						colors.sort(function () {
								return 0.5 - Math.random()
						})
						machines = machines.map((machine, index) => {
								machine.color = colors[index]
								return machine
						})
						// console.log(machines)

						this.setState({maxTotalWeight, routines, machines})
				})
		}

		render() {
				if (!this.props.activeUserId) {
						return (<Redirect to="/"/>)
				}

				return (
						<div>
								<h2 className="text-center py-5">
										<span className="text-red">
												<strong>{this.props.activeUserDisplayName}</strong>
										</span><br/>Workout History</h2>
								<div className="d-flex justify-content-between mb-4">
										<div>Career Stats</div>
										<div className="text-center">Total lbs:
												<h4>
														<strong>{this
																		.state
																		.totalWeight
																		.toLocaleString() + ' lbs'}</strong>
												</h4>
										</div>
										<div className="text-right">
												<div>Greatest lbs / workout:
														<h4>
																<strong>{this
																				.state
																				.maxRoutineWeight
																				.toLocaleString() + ' lbs'}</strong>
														</h4>
												</div>
										</div>
								</div>
								<table>
										<tbody>
												{this
														.state
														.routines
														.map(routine => {
																if (routine.exercises.length === 0) {
																		return null
																}
																return (<ChartRoutine
																		key={routine.id}
																		data={routine}
																		maxTotalWeight={this.state.maxTotalWeight}
																		machineData={this.state.machines}/>)
														})
}
										</tbody>
								</table>
								{(process.env.REACT_APP_MODE === 'APP') && <h5 className="mt-4">For more information visit{' '}
										<span className="text-red">
												<strong>2x20Fitness.com</strong>
										</span>
								</h5>
}
								{(process.env.REACT_APP_MODE === 'WWW') && <Button
										color="custom-primary"
										className="mt-4"
										onClick={this.props.usersActions.resetUser}>Logout</Button>}

						</div>
				)
		}
}

function mapStateToProps(state) {
		const retVal = {
				token: state.users.token,
				activeUserId: state.users.activeUserId,
				activeUserDisplayName: state.users.activeUserDisplayName
		}

		return retVal
}

function mapDispatchToProps(dispatch) {
		return {
				usersActions: bindActionCreators(usersActions, dispatch)
		}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEndRoutine)