import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import {
    Alert, Button, Card, CardBody, Form, FormGroup, Label, Input, Table
} from 'reactstrap'

import * as types from '../../../actions/actionTypes'

class AdminUsers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            search: '',
            users: [],
            selectedUser: null,
            username: '',
            error: ''
        }
    }

    handleInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleSearchChange = (event) => {
        this.setState({ [event.target.name]: event.target.value }, this.fetchUsers)
    }

    fetchUsers = () => {
        const { token } = this.props
        const { search } = this.state

        if (search.length < 3) {
            this.setState({ users: [] })
            return
        }

        axios.get(`${types.apiURL()}/user`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                search
            }
        }).then((response) => {
            const users = response.data
            this.setState({ users }, this.handleCancel)
        })
    }

    selectUser = (user) => {
        this.setState({ selectedUser: user, username: user.username })
    }

    handleCancel = () => {
        this.setState({ selectedUser: null, username: '', error: '' })
    }

    handleSubmit = () => {
        const { token } = this.props
        const { selectedUser, username } = this.state

        axios.put(`${types.apiURL()}/user/${selectedUser.id}`, {
            username
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            this.fetchUsers()
        }).catch((error) => {
            this.setState({ error: 'Unable to change, possible conflict with other user' })
        })
    }

    render() {
        const {
            search, users, selectedUser, username, error
        } = this.state

        return (
            <div className="Page my-4">
                <Form>
                    <FormGroup>
                        <Label for="search">
                            Search
                            {' '}
                            <i>(need at least 3 characters)</i>
                        </Label>
                        <Input
                            type="search"
                            name="search"
                            id="search"
                            placeholder="search"
                            value={search}
                            onChange={e => this.handleSearchChange(e)}
                        />
                    </FormGroup>
                </Form>

                {selectedUser
                    && (
                        <Card>
                            <CardBody>
                                <h3>{`User: ${selectedUser.nameFirst} ${selectedUser.nameLast}`}</h3>
                                <Form>
                                    <FormGroup>
                                        <Label for="username">Username:</Label>
                                        <Input
                                            type="username"
                                            name="username"
                                            id="username"
                                            placeholder="username"
                                            value={username}
                                            onChange={e => this.handleInputChange(e)}
                                        />
                                    </FormGroup>
                                </Form>

                                <Button color="custom-secondary mr-3" onClick={this.handleCancel}>CANCEL</Button>
                                <Button color="custom-primary" onClick={this.handleSubmit}>SUBMIT</Button>

                                {(error !== '') && <Alert color="danger" className="mt-4">{error}</Alert>}
                            </CardBody>
                        </Card>
                    )
                }

                {(users.length > 0) && (
                    <Table dark>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>

                            {users.map(user => (
                                <tr key={user.id}>
                                    <th scope="row">{user.id}</th>
                                    <td>{user.nameFirst}</td>
                                    <td>{user.nameLast}</td>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td>
                                        <Button color="primary" size="sm" onClick={() => this.selectUser(user)}>select</Button>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </Table>
                )}


            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        token: state.users.token
    }
}

function mapDispatchToProps(dispatch) {
    return { }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers)
