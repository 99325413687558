import React, {Component} from 'react'
import {
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Button
} from 'reactstrap'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import moment from 'moment'
import fontawesome from '@fortawesome/fontawesome'
import faPlus from '@fortawesome/fontawesome-free-solid/faPlus'
import faMinus from '@fortawesome/fontawesome-free-solid/faMinus'

import * as usersActions from '../../../actions/usersActions'
import * as machinesActions from '../../../actions/machinesActions'

import './RoutineExercise.scss'

const DEFAULT_REPS = 1
const DEFAULT_WEIGHT = 10
const DEFAULT_WEIGHT_MIN = 10
const DEFAULT_WEIGHT_MAX = 250
const DEFAULT_WEIGHT_STEP = 10

class RoutineExercise extends Component {
    constructor(props) {
        super(props)

        this.state = {
            inputReps: DEFAULT_REPS,
            inputWeight: DEFAULT_WEIGHT,
            message: '',
            submitStatus: 'UNSUBMITTED'
        }
        fontawesome.library.add(faPlus)
        fontawesome.library.add(faMinus)
    }

    componentWillMount() {
				this.props.usersActions.fetchUserMachineExercises(this.props.activeUserId, this.props.activeMachineId)
				if (this.props.activeUserIsAdmin) {
						this.props.machinesActions.refreshActiveMachine()
				}
    }

    componentWillReceiveProps(newProps) {
        if (this.state.inputWeight === 10 && newProps.activeUserMachineExercises.length > 0) {
            this.setState({inputReps: newProps.activeUserMachineExercises[0].reps, inputWeight: newProps.activeUserMachineExercises[0].weight})
        }

        if (newProps.activeUserSubmittedExercise && this.state.submitStatus !== 'SUBMITTED') {
            this.setState({submitStatus: 'SUBMITTED'})
        }
    }

    handleChangeInputReps = (event) => {
        let value = event.target.value
        value = Math.abs(value)
        if (value <= 0) {
            value = 1
        }
        this.setState({inputReps: String(value), message: ''})
    }
    handleWeightChangeInput = (event) => {
        this.setState({inputWeight: event.target.value})
    }
    handleRoutineExercise = () => {
        const reps = this.state.inputReps
        const weight = this.state.inputWeight

        if (reps > 0) {
            this.setState({message: '', submitStatus: 'SUBMITTING'})
            this.props.usersActions.submitRoutineExercise(this.props.activeUserRoutine.id, this.props.activeMachineId, reps, weight)
        } else {
            this.setState({message: 'Please check the entered values'})
        }
    }

    handlePlusMinus = (type, value) => {
        let newState = {}
        newState[type] = Number(this.state[type]) + value

        if (type === 'inputReps' && newState[type] < DEFAULT_REPS) {
            newState[type] = DEFAULT_REPS
        }
        if (type === 'inputWeight') {
            const weightMin = this.props.activeMachine.weightMin || DEFAULT_WEIGHT_MIN
            const weightMax = this.props.activeMachine.weightMax || DEFAULT_WEIGHT_MAX
            if (newState[type] < weightMin) 
                newState[type] = weightMin
            if (newState[type] > weightMax) 
                newState[type] = weightMax
        }

        this.setState(newState)
    }

    // handleRoutineComplete = () => {
    //     this.props.usersActions.completeRoutine(this.props.activeUserId, this.props.activeUserRoutine.id)
    //     this.setState({message: ''})
    // }

    handleSubmit = (event) => {
        event.preventDefault()
    }

    render() {
        let weightMin = this.props.activeMachine.weightMin || DEFAULT_WEIGHT_MIN
        let weightMax = this.props.activeMachine.weightMax || DEFAULT_WEIGHT_MAX
        let weightStep = this.props.activeMachine.weightStep || DEFAULT_WEIGHT_STEP

        let weightOptions = []
        for (let weight = weightMin; weight <= weightMax; weight += weightStep) {
            weightOptions.push(<option key={weight} value={weight}>{weight + ' '}
                lbs</option>)
        }
        return (<div className="RoutineExercise">

            <h2 className="text-center py-5">
                <span className="text-red">
                    <strong>{this.props.activeUserDisplayName}</strong>
                </span>{' '}
                is currently using this machine</h2>

            {(this.state.message !== '') && <div className="text-warning">{this.state.message}</div>}

            <Row className="mb-4">
                <Col>
                    <h2>Log Set:</h2>
                </Col>
            </Row>
            <Row>
                <Col sm="6" md="4">
                    <Form onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <Label for="exampleSelect" className="mb-3">Reps</Label>
                            <Button color="custom-primary exercise-increment top" onClick={() => this.handlePlusMinus('inputReps', 1)}>
                                <i className="fas fa-plus"></i>
                            </Button>
                            <Input className="text-center" type="number" min="1" name="inputReps" id="inputReps" value={this.state.inputReps} onChange={this.handleChangeInputReps}/>
                            <Button color="custom-primary exercise-increment bottom" onClick={() => this.handlePlusMinus('inputReps', -1)}>
                                <i className="fas fa-minus"></i>
                            </Button>
                        </FormGroup>
                    </Form>
                </Col>
                <Col sm="6" md="4">
                    <Form onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <Label for="exampleSelect" className="mb-3">Weight</Label>
                            <Button color="custom-primary exercise-increment top" onClick={() => this.handlePlusMinus('inputWeight', this.props.activeMachine.weightStep)}>
                                <i className="fas fa-plus"></i>
                            </Button>
                            <Input className="text-center" type="select" name="inputWeight" id="inputWeight" value={this.state.inputWeight} onChange={this.handleWeightChangeInput}>
                                {weightOptions}
                            </Input>
                            <Button color="custom-primary exercise-increment bottom" onClick={() => this.handlePlusMinus('inputWeight', -this.props.activeMachine.weightStep)}>
                                <i className="fas fa-minus"></i>
                            </Button>
                        </FormGroup>
                    </Form>
                </Col>
                <Col sm="12" md="4">
                    <Label for="exampleSelect" className="mb-3">Recent Activity</Label>
                    {
                        (this.props.activeUserMachineExercises.length === 0) && <Label className="mb-4">First exercise on
                                <strong>
                                    {' ' + this.props.activeMachine.name}</strong>
                            </Label>
                    }
                    {
                        (this.props.activeUserMachineExercises.length > 0) && <div className="mb-4">
                                {
                                    this.props.activeUserMachineExercises.map(item => {
                                        return (<div key={item.id} className="messaging">{item.reps}
                                            reps @ {item.weight + ' '}
                                            lbs - {moment(item.createdAt).fromNow()}</div>)
                                    })
                                }
                            </div>
                    }

                </Col>
            </Row>

            <Row>
                <Col>

                    {(this.state.submitStatus === 'UNSUBMITTED') && <Button color="custom-primary mt-4" onClick={this.handleRoutineExercise}>SUBMIT SET</Button>}
                    {
                        (this.state.submitStatus === 'SUBMITTING') && <div>
                                <h4 className="mt-2">Submitting...</h4>
                            </div>
                    }

                    {
                        (this.state.submitStatus === 'SUBMITTED') && <div>
                                <h4 className="mt-2">Set Submitted</h4>
                            </div>
                    }

                </Col>
            </Row>

        </div>)
    }
}

function mapStateToProps(state) {
    const retVal = {
        activeUserId: state.users.activeUserId,
        activeUserDisplayName: state.users.activeUserDisplayName,
        activeUserRoutine: state.users.activeUserRoutine,
        activeMachineId: state.machines.activeMachineId,
        activeMachine: state.machines.activeMachineData,
        activeUserMachineExercises: state.users.activeUserMachineExercises,
				activeUserSubmittedExercise: state.users.activeUserSubmittedExercise,
				activeUserIsAdmin: state.users.activeUserIsAdmin
    }

    return retVal
}

function mapDispatchToProps(dispatch) {
    return {
				usersActions: bindActionCreators(usersActions, dispatch),
				machinesActions: bindActionCreators(machinesActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutineExercise)