import axios from 'axios'

import * as types from './actionTypes'

export function setActiveFacility(facility) {
    return { type: types.SET_ACTIVE_FACILITY, facility }
}

export function receiveFacilities(json) {
    return { type: types.RECEIVE_FACILITIES, facilities: json }
}

export function fetchFacilities() {
    return (dispatch, getState) => axios.get(`${types.apiURL()}/facility`, {
        headers: {
            Authorization: `Bearer ${getState().users.token}`
        }
    }).then((response) => {
        dispatch(receiveFacilities(response.data))
    })
}
