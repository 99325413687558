import axios from 'axios'

import * as types from './actionTypes'

export function setActiveMachine(machine) {
    return { type: types.SET_ACTIVE_MACHINE, machine }
}

export function receiveMachines(json) {
    return { type: types.RECEIVE_MACHINES, machines: json }
}

export function fetchMachines() {
    return (dispatch, getState) => {
        const { activeFacilityId } = getState().facilities
        if (!activeFacilityId) {
            return null
        }

        return axios.get(`${types.apiURL()}/machine`, {
            headers: {
                Authorization: `Bearer ${getState().users.token}`
            },
            params: {
                facilityId: getState().facilities.activeFacilityId
            }
        }).then((response) => {
            dispatch(receiveMachines(response.data))
        })
    }
}

export function createMachine(item) {
    return (dispatch, getState) => axios.post(`${types.apiURL()}/machine`, item, {
        headers: {
            Authorization: `Bearer ${getState().users.token}`
        }
    }).then((response) => {
        dispatch(fetchMachines())
    })
}

export function updateMachine(item) {
    return (dispatch, getState) => axios.put(`${types.apiURL()}/machine/${item.id}`, item, {
        headers: {
            Authorization: `Bearer ${getState().users.token}`
        }
    }).then((response) => {
        dispatch(fetchMachines())
    })
}

export function refreshActiveMachine() {
    return (dispatch, getState) => {
        const activeMachineId = getState().machines.activeMachineId || null
        if (activeMachineId) {
            return axios.get(`${types.apiURL()}/machine/${activeMachineId}`, {
                headers: {
                    Authorization: `Bearer ${getState().users.token}`
                }
            }).then((response) => {
                dispatch(setActiveMachine(response.data))
            })
        }
        return null
    }
}
