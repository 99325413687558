import React, {Component} from 'react'
import {Container, Row, Col, Button} from 'reactstrap'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {bindActionCreators} from 'redux'

import * as usersActions from '../../actions/usersActions'
import * as machinesActions from '../../actions/machinesActions'

import './Footer.scss'

import AdminValidation from '../pages/admin/AdminValidation'

class Footer extends Component {

    handleLogout = () => {
        this.props.usersActions.resetUser()
    }

    handleValidate = () => {
        console.log('hi')
    }

    render() {
        if (!this.props.activeUserIsAdmin) {
            return null
        }

        if (!this.props.activeUserIsValidated) {
            return (<div className="Footer py-4">
                <Container>
                    <AdminValidation/>
                </Container>
            </div>)
        }

        return (<div className="Footer py-4">
            <Container>
                <Row>
                    <Col className="mb-4">
                        <Link to="/admin/">
                            <Button color="custom-secondary alt">Administration</Button>
                        </Link>
                    </Col>
                    <Col className="mb-4">
                        <Button color="custom-secondary alt" onClick={this.props.usersActions.resetStore}>RESET APP</Button>
                    </Col>
                    <Col className="mb-4">
                        <div>
                            <p>Debugging information:</p>

                            <div>activeUserId: {this.props.activeUserId}</div>
                            <div>activeFacilityId: {this.props.activeFacilityId}</div>
                            <div>activeMachineId: {this.props.activeMachineId}</div>
                            {
                                this.props.activeUserRoutine && <div>
                                        <div>activeUserRoutine:</div>
                                        <ul>
                                            <li>id: {this.props.activeUserRoutine.id}</li>
                                            <li># Exercises: {(this.props.activeUserRoutine.exercises) && this.props.activeUserRoutine.exercises.length}</li>
                                        </ul>
                                    </div>
                            }
                            {/* <div>Shortcuts</div> */}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}

function mapStateToProps(state) {
    const retVal = {
        activeUserId: state.users.activeUserId,
        activeUserName: state.users.activeUserName,
        activeUserIsAdmin: state.users.activeUserIsAdmin,
        activeUserIsValidated: state.users.activeUserIsValidated,
        activeUserRoutine: state.users.activeUserRoutine,
        activeMachineId: state.machines.activeMachineId,
        machines: state.machines.machines,
        activeFacilityId: state.facilities.activeFacilityId
    }
    return retVal
}

function mapDispatchToProps(dispatch) {
    return {
        usersActions: bindActionCreators(usersActions, dispatch),
        machinesActions: bindActionCreators(machinesActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
