import React, { Component } from 'react'
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Card,
    Button
} from 'reactstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import * as machinesActions from '../../../actions/machinesActions'

import MachineForm from './MachineForm'

class MachineMain extends Component {
    constructor(props) {
        super(props)
        console.log(props)

        this.state = {
            dropdownLabel: 'Select a machine...',
            dropdownOpen: false,
            selectedMachineId: null,
            create: false,
            edit: false
        }
    }

    componentWillMount() {
        this.props.machinesActions.fetchMachines()
    }

    handleToggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    handleSelectMachine = (item) => {
        this.setState({ selectedMachineId: item.id, edit: false, create: false })
    }

    handleSetActiveMachine = (item) => {
        this.props.machinesActions.setActiveMachine(item)
    }

    handleEditMachine = () => {
        this.setState({ edit: true, create: false })
    }

    handleNewMachine = () => {
        this.setState({ edit: false, create: true })
    }

    completeCreateEdit = () => {
        this.setState({ create: false, edit: false })
    }

    render() {
        const { activeFacilityId } = this.props

        // if no facility selected, can't select a machine
        if (!activeFacilityId) {
            return null
        }

        let machine = null
        if (this.state.selectedMachineId !== null) {
            machine = this.props.machines.find(item => String(item.id) === String(this.state.selectedMachineId))
        }

        return (
            <div className="Page my-4">
                {
                    (!this.state.create && !this.state.edit) && (
                        <Dropdown className="mb-4" isOpen={this.state.dropdownOpen} toggle={this.handleToggle}>
                            <DropdownToggle caret>{this.state.dropdownLabel}</DropdownToggle>
                            <DropdownMenu>
                                {
                                    this.props.machines.map(item => <DropdownItem key={item.id} value={item.id} onClick={() => this.handleSelectMachine(item)}>{item.name}</DropdownItem>)
                                }
                            </DropdownMenu>
                        </Dropdown>
                    )
                }

                {
                    (this.state.selectedMachineId && !this.state.create && !this.state.edit) && (
                        <Card className="mb-4">
                            <div className="card-body">
                                <h5 className="mb-3">{machine.name}</h5>
                                <Button color="custom-secondary" onClick={this.handleEditMachine} className="mr-3">EDIT</Button>
                                <Button color="custom-primary" onClick={() => this.handleSetActiveMachine(machine)}>ACTIVATE</Button>
                            </div>
                        </Card>
                    )
                }

                {
                    (!this.state.create && !this.state.edit) && (
                        <div>
                            <Button color="custom-primary" onClick={this.handleNewMachine} className="mb-4 mr-3">ADD NEW MACHINE</Button>
                            <Link to="/">
                                <Button color="custom-secondary" className="mb-4">RETURN HOME</Button>
                            </Link>
                        </div>
                    )
                }

                {
                    this.state.create && (
                        <Card>
                            <div className="card-body">
                                <h3>Create New Machine</h3>
                                <MachineForm mode="create" onComplete={this.completeCreateEdit} />
                            </div>
                        </Card>
                    )
                }
                {
                    this.state.edit && (
                        <Card>
                            <div className="card-body">
                                <h3>Edit Machine</h3>
                                <MachineForm mode="edit" data={machine} onComplete={this.completeCreateEdit} />
                            </div>
                        </Card>
                    )
                }

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        activeFacilityId: state.facilities.activeFacilityId,
        activeMachineId: state.machines.activeMachineId,
        machines: state.machines.machines
    }
}

function mapDispatchToProps(dispatch) {
    return {
        machinesActions: bindActionCreators(machinesActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MachineMain)
