import React, { Component } from 'react'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import axios from 'axios'

import config from '../../../config'

import * as usersActions from '../../../actions/usersActions'

const FETCH_EVERY_SECONDS = 30

class RoutinesLatest extends Component {
    constructor(props) {
        super(props)

        this.state = {
            openRoutines: []
        }
    }

    componentDidMount() {
        this.fetchRoutines()
        this.interval = setInterval(this.fetchRoutines, 1000 * FETCH_EVERY_SECONDS)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    fetchRoutines = () => {
        console.log('fetchRoutines()')
        const { activeFacilityId } = this.props

        // if we don't have an active facility, nothing to do
        if (!activeFacilityId) {
            console.log('no facility set, skipping fetch')
            return
        }

        axios.get(`${config.apiServer}/openRoutines`, {
            params: {
                isComplete: false,
                facilityId: activeFacilityId
            }
        }).then((response) => {
            this.setState({ openRoutines: response.data })
        })
    }

    handleSelectRoutine = (item) => {
        axios.get(`${config.apiServer}/login`, {
            params: {
                username: item.user.username
            }
        }).then((response) => {
            if (response.data.message) {
                this.setState({ message: response.data.message })
            } else {
                this.setState({ message: '' })
                this.props.usersActions.setActiveUser(response.data)
            }
        }).catch((error) => {
            if (error.response && error.response.data) {
                this.setState({ message: error.response.data.message })
            }
        })
    }

    render() {
        if (!this.state.openRoutines || this.state.openRoutines.length === 0) {
            return null
        }

        return (
            <div>
                <h2 className="mb-4">Recently Active Users:</h2>
                {
                    this.state.openRoutines.map(item => (
                        <Button
                            className="mr-3 mb-3"
                            color="custom-secondary"
                            key={item.id}
                            onClick={() => this.handleSelectRoutine(item)}
                        >
                            {`${item.user.nameFirst} ${item.user.nameLast}`}
                        </Button>
                    ))
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const retVal = {
        activeFacilityId: state.facilities.activeFacilityId
    }

    return retVal
}

function mapDispatchToProps(dispatch) {
    return {
        usersActions: bindActionCreators(usersActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutinesLatest)
