import React, { Component } from 'react'
import { connect } from 'react-redux'

import FacilityMain from '../facilities/FacilityMain'
import MachineMain from '../machines/MachineMain'

class AdminSetup extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        const { activeFacilityId } = this.props

        return (
            <div className="Page my-4">

                <FacilityMain />

                {activeFacilityId
                    && <MachineMain />
                }

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        activeFacilityId: state.facilities.activeFacilityId
    }
}

function mapDispatchToProps(dispatch) {
    return { }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSetup)
