import config from '../config'

export function apiURL() {
    return config.apiServer + config.apiServerPrefix
}

export const RESET_STORE = 'RESET_STORE'

export const RESET_USER = 'RESET_USER'
export const SET_ACTIVE_USER = 'SET_ACTIVE_USER'
export const VALIDATED_USER = 'VALIDATED_USER'

export const FETCH_USER_ROUTINE = 'FETCH_USER_ROUTINE'
export const RECEIVE_USER_ROUTINE = 'RECEIVE_USER_ROUTINE'

export const SUBMITTED_ROUTINE_EXERCISE = 'SUBMITTED_ROUTINE_EXERCISE'
export const RECEIVE_USER_MACHINE_EXERCISES = 'RECEIVE_USER_MACHINE_EXERCISES'

export const SET_ACTIVE_MACHINE = 'SET_ACTIVE_MACHINE'
export const FETCH_MACHINES = 'FETCH_MACHINES'
export const RECEIVE_MACHINES = 'RECEIVE_MACHINES'
export const CREATE_MACHINE = 'CREATE_MACHINE'
export const UPDATE_MACHINE = 'UPDATE_MACHINE'

export const SET_USER_REPORT_MACHINES = 'SET_USER_REPORT_MACHINES'

export const SET_ACTIVE_FACILITY = 'SET_ACTIVE_FACILITY'
export const FETCH_FACILITIES = 'FETCH_FACILITIES'
export const RECEIVE_FACILITIES = 'RECEIVE_FACILITIES'
