import packageJson from '../../package.json'

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state')
        if (serializedState === null) {
            return undefined
        }

        // Web mode - was broken by basic state change
        const state = JSON.parse(serializedState)
        console.log(state.users.appVersion, packageJson.version)
        if (state.users.appVersion !== packageJson.version) {
            console.log('resetting state')
            return {}
        }

        return state
    } catch (err) {
        return undefined
    }
}

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    } catch (err) {
        // TODO: handle err
    }
}
