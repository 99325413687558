module.exports = {

    selectColorsHSL(numColors, saturation = .95, lightness = .5) {
        let retVal = []

        const minHue = 350
        const maxHue = 415

        for (let i = 0; i < numColors; i++) {

            const valHue = Math.floor(i * ((maxHue - minHue) / (numColors - 1))) + minHue

            const valSat = (saturation * 100) + '%'
            const valLight = (lightness * 100) + '%'
            retVal.push('hsl(' + valHue + ', ' + valSat + ', ' + valLight + ')')
        }
        return retVal
    }

}