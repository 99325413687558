import { combineReducers } from 'redux'

import * as types from '../actions/actionTypes'

import users from './usersReducer'
import machines from './machinesReducer'
import facilities from './facilityReducer'

const appReducer = combineReducers({ users, machines, facilities })

const rootReducer = (state, action) => {
    if (action.type === types.RESET_STORE) {
        return appReducer({}, action)
    }

    return appReducer(state, action)
}

export default rootReducer
