import {
    RESET_USER,
    SET_ACTIVE_USER,
    VALIDATED_USER,
    RECEIVE_USER_ROUTINE,
    RECEIVE_USER_MACHINE_EXERCISES,
    SUBMITTED_ROUTINE_EXERCISE,
    SET_USER_REPORT_MACHINES
} from '../actions/actionTypes'
import packageJson from '../../package.json'

const initialState = {
    appVersion: packageJson.version,
    token: '',
    activeUserId: null,
    activeUserName: '',
    activeUserDisplayName: '',
    activeUserIsAdmin: false,
    activeUserIsValidated: false,
    activeUserRoutine: null,
    activeUserMachineExercises: [],
    activeUserSubmittedExercise: false,
    reportMachines: ['ALL']
}

export default function users(state = initialState, action) {
    switch (action.type) {
    case RESET_USER:
        console.log('RESET_USER action')
        return initialState

    case SET_ACTIVE_USER:
        console.log('SET_ACTIVE_USER Action')
        return Object.assign({}, state, {
            token: action.token || initialState.token,
            activeUserId: action.user.id || initialState.activeUserId,
            activeUserName: action.user.username || initialState.activeUserName,
            activeUserDisplayName: `${action.user.nameFirst} ${action.user.nameLast}` || initialState.activeUserDisplayName,
            activeUserIsAdmin: (action.user.isAdmin === true)
        })

    case VALIDATED_USER:
        console.log('VALIDATED_USER Action')
        return Object.assign({}, state, { activeUserIsValidated: action.isValidated })

    case RECEIVE_USER_ROUTINE:
        console.log('RECEIVE_USER_ROUTINE')
        return Object.assign({}, state, { activeUserRoutine: action.routine })

    case RECEIVE_USER_MACHINE_EXERCISES:
        console.log('RECEIVE_USER_MACHINE_EXERCISES')
        return Object.assign({}, state, { activeUserMachineExercises: action.exercises })

    case SUBMITTED_ROUTINE_EXERCISE:
        console.log('SUBMITTED_ROUTINE_EXERCISE')
        return Object.assign({}, state, { activeUserSubmittedExercise: true })

    case SET_USER_REPORT_MACHINES:
        console.log('SET_USER_REPORT_MACHINES')
        return Object.assign({}, state, { reportMachines: action.machines })

    default:
        return state
    }
}
