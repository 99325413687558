import axios from 'axios'

import * as types from './actionTypes'

export function resetStore() {
    return { type: types.RESET_STORE }
}

export function resetUser() {
    return { type: types.RESET_USER }
}

export function setActiveUser(data) {
    return { type: types.SET_ACTIVE_USER, user: data.user, token: data.token }
}

export function validatedUser(isValidated = false) {
    return { type: types.VALIDATED_USER, isValidated }
}

/* ROUTINE related */
export function receiveUserRoutine(routine) {
    return { type: types.RECEIVE_USER_ROUTINE, routine }
}

export function fetchUserRoutine(force = false) { // force means to create one if none returned
    return (dispatch, getState) => axios.get(`${types.apiURL()}/routine`, {
        headers: {
            Authorization: `Bearer ${getState().users.token}`
        },
        params: {
            isComplete: false
        }
    }).then((response) => {
        if (force && response.data.length === 0) {
            dispatch(createUserRoutine())
        } else {
            dispatch(receiveUserRoutine(response.data[0]))
        }
    })
}

export function createUserRoutine() {
    return (dispatch, getState) => axios.post(`${types.apiURL()}/routine`, {
        facilityId: getState().facilities.activeFacilityId
    }, {
        headers: {
            Authorization: `Bearer ${getState().users.token}`
        }
    }).then((response) => {
        dispatch(receiveUserRoutine(response.data))
    })
}

// export function fetchSpecificUserRoutine(userId, routineId) {
//     return(dispatch, getState) => {
//         return axios.get(types.apiURL() + '/user/' + userId + '/routine/' + routineId, {
//             headers: {
//                 Authorization: 'Bearer ' + getState().users.token
//             }
//         }).then(response => {
//             dispatch(receiveUserRoutine(response.data))
//         })
//     }
// }

export function submitRoutineExercise(routineId, machineId, reps, weight) {
    return (dispatch, getState) => axios.post(`${types.apiURL()}/routine/${routineId}/exercise`, {
        machineId,
        reps,
        weight
    }, {
        headers: {
            Authorization: `Bearer ${getState().users.token}`
        }
    }).then((response) => {
        dispatch(submittedRoutineExercise())
        dispatch(fetchUserMachineExercises())
    })
}

export function submittedRoutineExercise() {
    return { type: types.SUBMITTED_ROUTINE_EXERCISE }
}

export function completeRoutine() {
    return (dispatch, getState) => {
        if (getState().users.activeUserRoutine) {
            return axios.put(`${types.apiURL()}/routine/${getState().users.activeUserRoutine.id}`, {
                isComplete: true
            }, {
                headers: {
                    Authorization: `Bearer ${getState().users.token}`
                }
            }).then((response) => {
                dispatch(receiveUserRoutine(response.data))
            })
        }
    }
}

/* user -> machine -> exercise */
export function receiveUserMachineExercises(exercises) {
    return { type: types.RECEIVE_USER_MACHINE_EXERCISES, exercises }
}

export function fetchUserMachineExercises() {
    return (dispatch, getState) => axios.get(`${types.apiURL()}/machine/${getState().machines.activeMachineId}/exercise`, {
        headers: {
            Authorization: `Bearer ${getState().users.token}`
        },
        params: {
            limit: 3
        }
    }).then((response) => {
        dispatch(receiveUserMachineExercises(response.data))
    })
}

export function setUserReportMachine(machines) {
    return { type: types.SET_USER_REPORT_MACHINES, machines }
}
