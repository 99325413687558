import React, {Component} from 'react'
import {} from 'reactstrap'

import './Background.scss'

class Background extends Component {
    render() {
        return (<div className="Background"></div>)
    }
}

export default Background