import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'

import * as usersActions from '../../../actions/usersActions'

import RoutineExercise from '../routine/RoutineExercise'
import UserEndRoutine from './UserEndRoutine'

class UserMain extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    componentWillMount() {
        // only fetch a new one if we don't have one and if configured for a machine
        if (!this.props.activeUserRoutine && this.props.activeMachineId) {
            this.props.usersActions.fetchUserRoutine(true)
        }
    }

    render() {
        if (!this.props.activeUserId) { // if not logged in, go home
            return (<Redirect to="/" />)
        }

        if (!this.props.activeMachineId) { // if no machine active, show message
            return (
                <div>
                    <h2 className="text-center py-5">
                        This machine has not been setup,
                        <br />
                        please alert support.
                    </h2>
                </div>
            )
        }

        if (this.props.activeUserRoutine && this.props.activeUserRoutine.isComplete) {
            return (
                <div>
                    <UserEndRoutine />
                </div>
            )
        }

        return (
            <div>
                {this.props.activeUserRoutine && <RoutineExercise />}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const retVal = {
        activeUserId: state.users.activeUserId,
        activeUserDisplayName: state.users.activeUserDisplayName,
        activeUserRoutine: state.users.activeUserRoutine,
        activeMachineId: state.machines.activeMachineId,
        activeMachine: state.machines.activeMachineData
    }

    return retVal
}

function mapDispatchToProps(dispatch) {
    return {
        usersActions: bindActionCreators(usersActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMain)
