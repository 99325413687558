import React, { Component } from 'react'
import {
    TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap'
import classnames from 'classnames'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as usersActions from '../../../actions/usersActions'

import AdminSetup from './AdminSetup'
import AdminUsers from './AdminUsers'

class Admin extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeTab: '1'
        }
    }

    toggle(tab) {
        this.setState({ activeTab: tab })
    }

    render() {
        const { activeUserIsAdmin } = this.props
        if (!activeUserIsAdmin) {
            return (<Redirect to="/" />)
        }

        const { activeTab } = this.state

        return (
            <div className="Page my-4">

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { this.toggle('1') }}
                        >
                            Setup
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { this.toggle('2') }}
                        >
                            Users
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <AdminSetup />
                    </TabPane>

                    <TabPane tabId="2">
                        <AdminUsers />
                    </TabPane>
                </TabContent>

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        activeUserIsAdmin: state.users.activeUserIsAdmin
    }
}

function mapDispatchToProps(dispatch) {
    return {
        usersActions: bindActionCreators(usersActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
