import React, { Component } from 'react'
import {
    Container, Row, Col, Button
} from 'reactstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as usersActions from '../../actions/usersActions'

import './Footer.scss'

class Footer extends Component {
    handleLogout = () => {
        if (!this.props.activeMachineId) {
            this.handleRoutineComplete()
        }
        this.props.usersActions.resetUser()
    }

    handleRoutineComplete = () => {
        this.props.usersActions.completeRoutine()
    }

    render() {
        if (this.props.activeUserId && !this.props.activeMachineId) { // if no machine active, show message
            return (
                <div className="Footer pt-4 pb-4 mt-5">
                    <Container>
                        <Row>
                            <Col className="text-right">
                                <Button color="custom-secondary alt" onClick={this.handleLogout}>SIGN OUT</Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        }

        if (this.props.activeUserId) {
            return (
                <div className="Footer pt-4 pb-4 mt-5">
                    <Container>
                        <Row>
                            {
                                (this.props.activeUserRoutine && !this.props.activeUserRoutine.isComplete) && (
                                    <Col>
                                        <Button color="custom-secondary" onClick={this.handleLogout}>MOVE TO NEW MACHINE</Button>
                                    </Col>
                                )
                            }

                            {
                                (this.props.activeUserRoutine && !this.props.activeUserRoutine.isComplete) && (
                                    <Col className="text-right">
                                        <Button color="custom-secondary alt" onClick={this.handleRoutineComplete}>END WORKOUT</Button>
                                    </Col>
                                )
                            }

                            {
                                (this.props.activeUserRoutine && this.props.activeUserRoutine.isComplete) && (
                                    <Col className="text-right">
                                        <Button color="custom-secondary alt" onClick={this.handleLogout}>SIGN OUT</Button>
                                    </Col>
                                )
                            }

                        </Row>
                    </Container>
                </div>
            )
        }

        return null
    }
}

function mapStateToProps(state) {
    const retVal = {
        activeUserId: state.users.activeUserId,
        activeUserRoutine: state.users.activeUserRoutine,
        activeMachineId: state.machines.activeMachineId
    }
    return retVal
}

function mapDispatchToProps(dispatch) {
    return {
        usersActions: bindActionCreators(usersActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
