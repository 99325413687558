import React, { Component } from 'react'
import {
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Input
} from 'reactstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'
import axios from 'axios'

import * as usersActions from '../../actions/usersActions'

import config from '../../config'

import RoutinesLatest from './routine/RoutinesLatest'

class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            username: '',
            message: ''
        }
    }

    handleChangeUsername = (event) => {
        this.setState({ username: event.target.value })
    }

    handleLogin = (event) => {
        event.preventDefault()

        if (this.state.username === '') {
            this.setState({ message: 'Please enter username first' })
        } else {
            axios.get(`${config.apiServer}/login`, {
                params: {
                    username: this.state.username
                }
            }).then((response) => {
                console.log(response.data)
                if (response.data.message) {
                    this.setState({ message: response.data.message })
                } else {
                    this.setState({ message: '' })
                    this.props.usersActions.setActiveUser(response.data)
                }
            }).catch((error) => {
                if (error.response && error.response.data) {
                    this.setState({ message: error.response.data.message })
                }
            })
        }
    }

    render() {
        if (this.props.activeUserId) {
            return (<Redirect to="/user" />)
        }

        return (
            <div className="Page">
                <h3 className="text-center py-5">
                    Type in your Username to begin tracking,
                    <br />
                    or choose your name from the recent list.
                </h3>

                <Row>
                    <Col sm="12" md="6" className="mb-5 mb-md-0">
                        <h2 className="mb-4">Start your workout:</h2>

                        <Form className="mb-4" onSubmit={this.handleLogin}>
                            <FormGroup className="mb-4">
                                <Input type="text" name="username" id="username" placeholder="Username" value={this.state.username} onChange={this.handleChangeUsername} />
                            </FormGroup>

                            <Button color="custom-primary w-100" onClick={this.handleLogin}>LOGIN TO BEGIN TRACKING</Button>

                            {this.state.message !== '' && <div className="text-warning mt-2">{this.state.message}</div>}
                        </Form>

                    </Col>
                    <Col sm="12" md="6">
                        <RoutinesLatest />
                    </Col>
                </Row>

            </div>
        )
    }
}

function mapStateToProps(state) {
    return { activeUserId: state.users.activeUserId }
}

function mapDispatchToProps(dispatch) {
    return {
        usersActions: bindActionCreators(usersActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
