import React, {Component} from 'react'
import {Form, FormGroup, Input, Button} from 'reactstrap'
import axios from 'axios'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {withRouter} from 'react-router-dom'

import * as usersActions from '../../../actions/usersActions'

import {toProperCase} from '../../../utilities/string'

import config from '../../../config'

const minUsernameLength = 5

class UserSetupCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            inputUsername: '',
            message: '',
            sucess: false,
            user: null
        }
    }

    handleChangeInputUsername = (event) => {
        this.setState({inputUsername: event.target.value})
    }

    handleCancel = (event) => {
        this.props.history.push('/')
    }

    handleSubmit = (event) => {
        event.preventDefault()

        if (this.state.inputUsername.length < minUsernameLength) {
            this.setState({
                message: 'Please create a username with at least ' + minUsernameLength + ' characters.'
            })
        } else {
            this.setState({message: ''})

            axios.post(config.apiServer + '/userCreate', {
                nameFirst: toProperCase(this.props.remoteUser.firstName),
                nameLast: toProperCase(this.props.remoteUser.lastName),
                email: this.props.remoteUser.email.toLowerCase(),
                username: this.state.inputUsername,
                remoteId: this.props.remoteUser.uniqueId
            }).then(response => {
                this.setState({message: '', success: true, user: response.data})
            }).catch(err => {
                this.setState({message: 'Unable to create username, please change and retry.'})
            })
        }
    }

    handleLogin = (event) => {
        axios.get(config.apiServer + '/login', {
            params: {
                username: this.state.user.username
            }
        }).then(response => {
            this.props.usersActions.setActiveUser(response.data)
            this.props.history.push('/')
        })
    }

    render() {

        return (<div>
            <h5>{toProperCase(this.props.remoteUser.firstName + ' ' + this.props.remoteUser.lastName)}</h5>
            <h5 className="text-muted mb-4">
                <em>{this.props.remoteUser.email.toLowerCase()}</em>
            </h5>
            <p>Please select a username for quicker login in the future:</p>

            <Form className="mb-4" inline={true} onSubmit={this.handleSubmit}>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Input type="text" name="username" id="username" placeholder="New Username" value={this.state.inputUsername} onChange={this.handleChangeInputUsername}/>
                </FormGroup>
            </Form>

            {this.state.message !== '' && <div className="text-warning mt-4">{this.state.message}</div>}

            {
                !this.state.success && <div>
                        <Button color="custom-secondary" className="mr-3" onClick={this.handleCancel}>CANCEL</Button>
                        <Button color="custom-primary" onClick={this.handleSubmit}>SET MY USERNAME</Button>
                    </div>
            }

            {
                this.state.success && <div>
                        <p className="text-success">Account created successfully,</p>
                        <Button color="custom-primary" onClick={this.handleLogin}>LOGIN</Button>
                    </div>
            }

        </div>)
    }
}

function mapStateToProps(state) {
    const retVal = {}

    return retVal
}

function mapDispatchToProps(dispatch) {
    return {
        usersActions: bindActionCreators(usersActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserSetupCreate))
