import React, {Component} from 'react'

import './Version.scss'

class Version extends Component {

    render() {
        return (<div className="Version">{this.props.children}</div>)
    }
}

export default Version