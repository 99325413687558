import React, {Component} from 'react'
import {Form, FormGroup, Input, Button} from 'reactstrap'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import axios from 'axios'

import * as usersActions from '../../../actions/usersActions'

import config from '../../../config'

class AdminValidation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            inputPassword: '',
            message: ''
        }
    }

    handleChangePassword = (event) => {
        this.setState({inputPassword: event.target.value})
    }

    handleSubmit = (event) => {
        event.preventDefault()

        if (this.state.inputPassword === '') {
            this.setState({message: 'Please enter password'})
            return
        }

        this.setState({message: ''})
        axios.get(config.apiServer + '/login', {
            Authorization: 'Bearer ' + this.props.token,
            params: {
                username: this.props.activeUserName,
                password: this.state.inputPassword
            }
        }).then(response => {
            if (response.data && response.data.user && response.data.user.isValidated) {
                this.props.usersActions.validatedUser(true)
            } else {
                this.setState({message: 'Unable to validate user'})
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.data && error.response.data.message) {
                    this.setState({message: error.response.data.message})
                }
            }
        })
    }

    render() {
        if (this.props.activeUserIsValidated) {
            return (<div>User Validated</div>)
        }

        return (<div>
            {this.state.message !== '' && <div className="text-warning mb-2">{this.state.message}</div>}
            <Form inline={true} onSubmit={this.handleSubmit}>
                <FormGroup>
                    <Input type="password" name="password" id="password" placeholder="Password" className="w-25 alt" value={this.state.inputPassword} onChange={this.handleChangePassword}/>
                    <Button color="custom-primary" onClick={this.handleSubmit}>AUTHENTICATE AS ADMIN</Button>
                </FormGroup>
            </Form>
        </div>)
    }
}

function mapStateToProps(state) {
    const retVal = {
        token: state.users.token,
        activeUserId: state.users.activeUserId,
        activeUserIsAdmin: state.users.activeUserIsAdmin,
        activeUserName: state.users.activeUserName,
        activeUserIsValidated: state.users.activeUserIsValidated
    }
    return retVal
}

function mapDispatchToProps(dispatch) {
    return {
        usersActions: bindActionCreators(usersActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminValidation)