let env = process.env.NODE_ENV || 'development'

let configDefaults = {
		development: {
				apiServer: 'http://localhost:2999',
				apiServerPrefix: '/api'
		},
		production: {
				apiServer: 'https://api.2x20fitness.com:2999',
				apiServerPrefix: '/api'
		}
}

let config = configDefaults[env]

if (process.env.REACT_APP_apiServer) {
		config.apiServer = process.env.REACT_APP_apiServer
}
if (process.env.REACT_APP_apiServerPrefix) {
		config.apiServerPrefix = process.env.REACT_APP_apiServerPrefix
}

export default config