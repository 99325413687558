import React, {Component} from 'react'
import moment from 'moment'
import {connect} from 'react-redux'

import ChartRoutineExercise from './ChartRoutineExercise'

import './ChartRoutine.scss'

class ChartRoutine extends Component {

		getColorForMachineId = (machineId) => {
				let machineDatum
				this
						.props
						.machineData
						.forEach(machine => {
								if (machine.id === machineId) {
										machineDatum = machine
								}
						})
				return machineDatum.color
		}

		componentDidUpdate(prevProps) {
				// Typical usage (don't forget to compare props):
				if (this.props.selectedMachines.length > 0 && this.props.selectedMachines[0] !== prevProps.selectedMachines[0]) {
						// console.log('new stuff')
				}
		}

		handleClick = () => {
				// console.log(this.props.data)
		}

		render() {
				const exerciseStyles = {
						maxWidth: 100 * (this.props.data.totalWeight / this.props.maxTotalWeight) + '%'
				}

				let totalText = [
						this
								.props
								.data
								.totalWeight
								.toLocaleString() + ' lbs'
				]
				if (this.props.selectedMachines.length > 0 && this.props.selectedMachines[0] !== 'ALL') {
						totalText = []
						this
								.props
								.data
								.exercises
								.forEach(exercise => {
										if (this.props.selectedMachines.includes(exercise.Machine.id)) {
												totalText.push(exercise.reps + ' reps @' + exercise.weight + ' lbs')
										}
								})
				}

				return (
						<tr className="ChartRoutine" onClick={this.handleClick}>
								<td className="date">{moment(this.props.data.completedAt).format("MMM Do")}</td>
								<td className="exercises">
										<div className="exercise-container" style={exerciseStyles}>
												{this
														.props
														.data
														.exercises
														.map(exercise => {
																if (this.props.selectedMachines.includes('ALL') || this.props.selectedMachines.includes(exercise.Machine.id)) {
																		this.getColorForMachineId(exercise.Machine.id)
																		const exerciseWeight = exercise.reps * exercise.weight
																		const percent = 100 * exerciseWeight / this.props.data.totalWeight
																		const styles = {
																				width: percent + '%'
																		}
																		const stylesFiller = {
																				backgroundColor: this.getColorForMachineId(exercise.Machine.id)
																		}
																		return (<ChartRoutineExercise
																				key={exercise.id}
																				styles={styles}
																				stylesFiller={stylesFiller}
																				exercise={exercise}/>)
																} else {
																		return (
																				null
																		)
																}
														})}
										</div>
								</td>
								<td className="total">{totalText.map((text, index) => {
												return <div key={index}>{text}</div>
										})}</td>
						</tr>
				)
		}
}

function mapStateToProps(state) {
		const retVal = {
				selectedMachines: state.users.reportMachines
		}

		return retVal
}

function mapDispatchToProps(dispatch) {
		return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartRoutine)