import React, { Component } from 'react'
import {
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from '../../../actions/facilitiesActions'
import * as actionsMachines from '../../../actions/machinesActions'

class FacilityMain extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dropdownLabel: 'Select a facility...',
            dropdownOpen: false
        }
    }

    componentWillMount() {
        const { facilitiesActions } = this.props
        facilitiesActions.fetchFacilities()
    }

    handleToggle = () => {
        this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }))
    }

    handleSelect = (item) => {
        const { facilitiesActions } = this.props
        facilitiesActions.setActiveFacility(item)
    }

    handleReset = () => {
        const { facilitiesActions } = this.props
        facilitiesActions.setActiveFacility({})

        const { machinesActions } = this.props
        machinesActions.setActiveMachine({})

        facilitiesActions.fetchFacilities()
    }

    render() {
        const { activeFacilityId, activeFacilityData } = this.props
        if (activeFacilityId) {
            return (
                <div className="Page my-4 d-flex align-items-center">
                    <h3>{`Facility: ${activeFacilityData.name}`}</h3>
                    <Button className="mx-4" color="primary" size="sm" onClick={() => this.handleReset()}>change</Button>
                </div>
            )
        }


        const { facilities } = this.props
        const { dropdownOpen, dropdownLabel } = this.state

        return (
            <div className="Page my-4">
                {facilities
                    && (
                        <Dropdown className="mb-4" isOpen={dropdownOpen} toggle={this.handleToggle}>
                            <DropdownToggle caret>{dropdownLabel}</DropdownToggle>
                            <DropdownMenu>
                                {
                                    facilities.map(item => (
                                        <DropdownItem
                                            key={item.id}
                                            value={item.id}
                                            onClick={() => this.handleSelect(item)}
                                        >
                                            {item.name}
                                        </DropdownItem>
                                    ))
                                }
                            </DropdownMenu>
                        </Dropdown>
                    )
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        activeFacilityId: state.facilities.activeFacilityId,
        activeFacilityData: state.facilities.activeFacilityData,
        facilities: state.facilities.facilities
    }
}

function mapDispatchToProps(dispatch) {
    return {
        facilitiesActions: bindActionCreators(actions, dispatch),
        machinesActions: bindActionCreators(actionsMachines, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacilityMain)
