import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import throttle from 'lodash/throttle'
import rootReducer from '../reducers/rootReducer'

import { loadState, saveState } from './localStorage'

export default function configureStore() {
    const persistedState = loadState()

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

    const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(thunk)))

    store.subscribe(throttle(() => {
        saveState(store.getState())
    }), 1000)

    return store
}
