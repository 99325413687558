import React, { Component } from 'react'
import { connect } from 'react-redux'

import './GymLocation.scss'

class GymLocation extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        const { activeFacilityData } = this.props
        const name = (activeFacilityData) ? activeFacilityData.name : ''

        return (<div className="GymLocation">{name}</div>)
    }
}


function mapStateToProps(state) {
    const retVal = {
        activeFacilityData: state.facilities.activeFacilityData
    }
    return retVal
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(GymLocation)
