import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'
import packageJSON from '../package.alias.json'

import './Main.scss'

import Background from './shell/Background'

import Header from './shell/Header'
import Footer from './shell/Footer'
import FooterAdmin from './shell/FooterAdmin'

import Login from './pages/Login'
import Admin from './pages/admin/Admin'
import UserMain from './pages/users/UserMain'
import UserSetup from './pages/users/UserSetup'
import UserSetPassword from './pages/users/UserSetPassword'

import Version from './shell/Version'
import GymLocation from './shell/GymLocation'

class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        console.log('running version: ', packageJSON.version)
        return (
            <Router>
                <div className="Main-wrapper">
                    <Background />
                    <div>
                        <Header />
                        <Container className="Main">
                            <Row>
                                <Col>
                                    <Route exact path="/" component={Login} />
                                    <Route path="/admin" component={Admin} />
                                    <Route path="/user" component={UserMain} />
                                    <Route path="/userSetup" component={UserSetup} />
                                    <Route path="/userPassword" component={UserSetPassword} />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div>
                        <Footer />
                        <FooterAdmin />
                    </div>
                    <GymLocation />
                    <Version>
                        {`v.${packageJSON.version}.a`}
                    </Version>
                </div>
            </Router>
        )
    }
}

export default Main
